import { WhiteBtn } from '../components/LearnMore';
import { CenterDesc } from '../components/CenterDesc';
import { FitItem } from '../components/FitItem';
import ar_log from '../assets/images/ar_log_img.png';
import vr_log from '../assets/images/vr_log_img.png';
import vr_community from '../assets/images/ar_arm_tapped.png';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VideoBox } from '../components/VideoBox';
import { HeroWrap } from '../components/HeroWrap';
import csr_poster from '../assets/images/csr-corporate-social.webp';
import check_icon from '../assets/images/basic/check_bold.svg';
import product_img from '../assets/images/product_visualization.png';
import maintence_trouble from '../assets/images/maintenance_trouble.png';
import driving_training from '../assets/images/driver_training.png';
import damage_img from '../assets/images/danage_claim.png';
import simulate_img from '../assets/images/simulation_training.png';
import Scrollbar from '../components/scrollbar';
import { Helmet } from 'react-helmet';
import {
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
  Textarea,
} from 'flowbite-react';
import { backend_baseUrl } from './Contact';
import { useForm } from 'react-hook-form';
import { InquiryResultModal } from '../components/InquiryResultModal';
import { showCaseStudy } from '../store';
import { useRecoilState } from 'recoil';
import { HeaderBtn } from '../components/layouts/Header';

export type projectContactType = {
  email: string;
  case_kind: string;
  content: string;
};
export const Home = () => {
  const [eduType, setEduType] = useState('VR/AR');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showCase, setShowCase] = useRecoilState(showCaseStudy);
  const navigate = useNavigate();
  const {
    register,
    getValues,
    watch,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
  } = useForm<projectContactType>();
  const { content } = watch();
  const nonEmpty = content ? content?.trim().length > 0 : false;
  const sendInquiry = async () => {
    if (backend_baseUrl) {
      const data = getValues();
      const url = `${backend_baseUrl}/api/Contact/mmc_send_inquiry`;
      setIsSending(true);
      try {
        await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        setIsSuccess(true);
      } catch (ex) {
        setIsSuccess(false);
      }
      setIsSending(false);
      setShowMessage(true);
    }
  };

  return (
    <div className="grid gap-y-5 xl:gap-y-10">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | ARSOME Technology</title>
        <meta
          name="description"
          content="ARSOME Technology develops augmented, &amp; mixed reality programs and products for businesses. Start a project today."
        ></meta>
      </Helmet>
      <HeroWrap className="max-md:hidden md:main_home_bg">
        <>
          <h6 className="font-bold md:text-xl 2xl:text-5xl">
            Personalized AR/VR Training
          </h6>
          <p className="text-sm 2xl:text-lg">
            We don't create AR/VR just because we're awesome. Every engagement
            serves a purpose - providing our clients with the most advanced
            training programs to empower their teams. What truly sets us apart
            is our customized development, AI integration, and unwavering
            commitment to exceeding client expectations. That's why the most
            important organizations rely on our expertise, like the U.S.
            Department of Defense.
          </p>
          <div className="flex gap-3">
            <WhiteBtn
              title="Learn More"
              onAction={() => {
                navigate('/AR_VR_Custom_Development');
              }}
            />
            <WhiteBtn
              title="Case Study"
              onAction={() => {
                setShowCase(true);
              }}
            />
          </div>
        </>
      </HeroWrap>
      <div className="max-md:block md:hidden">
        <HeroWrap className="max-md:block md:hidden">
          <h6 className="font-bold text-xl text-[#2E90F2] text-center">
            Personalized AR/VR Training
          </h6>
          <p className=" font-light text-center text-[#383231]">
            We don't create AR/VR just because we're awesome. Every engagement
            serves a purpose - providing our clients with the most advanced
            training programs to empower their teams. What truly sets us apart is
            our customized development, AI integration, and unwavering commitment
            to exceeding client expectations. That's why the most important
            organizations rely on our expertise, like the U.S. Department of
            Defense.
          </p>
          <div className="flex justify-between space-x-3">
            <HeaderBtn
              title="Learn More"
              onAction={() => {
                navigate('/AR_VR_Custom_Development');
              }}
            />
            <HeaderBtn
              title="Case Study"
              onAction={() => {
                setShowCase(true);
              }}
            />
          </div>
        </HeroWrap>
      </div>
      <CenterDesc
        title="Experience Matters. Engage Experts."
        description="After launching in 2017, we’ve developed 100+ custom AR/VR
        training programs to increase employee safety and competency. Our internal
        platforms allow us to design training simulations better and smarter. Our
        people are the best and power each experience with exceptional authenticity
        and realism."
      />
      <section className="w-full relative max-sm:min-h-[390px] xl:h-[522px] md:h-[483px]">
        <div className="z-0 hidden md:block absolute right_round_bg md:h-[483px] xl:h-[522px] w-2/3 right-0"></div>
        <div className="static z-10 w-full md:absolute ">
          <div
            className={`center_desc_wrap md:center_desc_nobg flex flex-col-reverse md:flex-row justify-center gap-5 align-middle xl:h-[522px] md:h-[483px] py-4`}
          >
            <div className="flex justify-center">
              <VideoBox
                className="w-[350px] md:w-[370px] xl:w-[734px] min-h-[250px]"
                url="https://youtu.be/wBgskfrBqyA"
              />
            </div>
            <div className="flex items-center justify-center text-white md:justify-normal">
              <div className="w-[350px] md:w-[375px] xl:w-[631px] grid gap-y-4 xl:gap-y-8">
                <h6 className="mb-2 text-xl font-bold xl:text-5xl max-md:text-center">
                  AR Parts + Pieces Platform
                </h6>
                <p className="xl:text-lg max-md:text-center">
                  Teach employees how to identify parts and pieces of complex
                  machines, engines, vehicles, weaponry, etc., by removing
                  layers and simulating processes. Improve employee competency
                  and readiness around system troubleshooting, part maintenance,
                  and part replacement by leveraging the benefits of our
                  proprietary and internal AR Parts + Pieces Platform.
                  Supervisors receive data-driven insights powered by artificial
                  intelligence to assist with measuring, and correcting
                  personnel performance over time.
                </p>
                <div className="xl:flex xl:space-x-3 max-xl:space-y-2">
                  <div className="flex space-x-1 max-md:justify-center">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#FCDC3E] font-bold max-xl:text-sm">
                        Increased Readiness
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-1 max-md:justify-center">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#FCDC3E] font-bold max-xl:text-sm">
                        Increased Safety
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-1 max-md:justify-center">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#FCDC3E] font-bold max-xl:text-sm">
                        Advanced Training
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex max-md:justify-center">
                  {/* <WhiteBtn
                    title="Learn More"
                    onAction={() => {
                      navigate("/AR_VR_Custom_Development");
                    }}
                  /> */}
                  <button
                    className="px-4 py-2 font-bold bg-white rounded-lg max-md:w-full text-my-gray xl:text-base"
                    onClick={() => {
                      setShowCase(true);
                    }}
                  >
                    Case Study
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="md:ar_vr_sec_bg md:w-full xl:h-[499px] md:h-[266px] md:flex md:justify-center md:items-center max-md:border max-md:border-[#FCDC3E] max-md:border-x-0">
        <div className="grid p-4 md:bg_workforce md:text-white md:w-9/12 xl:w-7/12 md:rounded-lg gap-y-4">
          <div className="text-center xl:text-xl max-md:text-sm">
            Learn how we bring 2D training manuals to life with 3D AR
            step-by-step guides and instructions. Immersive training manuals are the new
            standard.
          </div>
          <div className="flex justify-center">
            <button
              className=" max-xl:text-md text-[#383231] max-md:text-white bg-white px-4 py-2 font-bold rounded-lg max-md:w-full max-md:blue_btn"
              onClick={() => {
                navigate('/Contact');
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </section>
      {/* <section className="max-md:border max-md:border-y max-md:border-[#2E90F2]">
        <div className="xl:yellow_decoration xl:xl:w-[1200px] xl:py-6 xl:mx-auto">
          <div className="xl:download_case_shadow xl:w-[1150px] p-8 max-md:p-4 md:bg-[#2D8DED] text-white xl:rounded-xl xl:mx-auto grid gap-y-[20px]">
            <h6 className="font-bold xl:text-2xl max-md:text-[#2E90F2] max-md:text-center max-md:font-bold">
              Do you require an AR/VR program to train people on maintaining or
              operating heavy equipment, weapons, or vehicles? Something else?
              Tell us about your training challenges so we can present a
              relevant case study.
            </h6>
            <div className="text-center">
              <button
                className=" max-xl:text-md text-[#383231] max-md:text-white bg-white px-4 py-2 font-bold rounded-lg max-md:w-full max-md:blue_btn"
                onClick={() => {
                  setShowCase(true);
                }}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section> */}
      <section className="w-full relative max-sm:min-h-[390px] md:h-[533px] xl:h-[522px]">
        <div className="z-0 absolute left_round_bg md:h-[533px] xl:h-[522px] w-2/3 left-0"></div>
        <div className="static z-10 w-full md:absolute ">
          <div
            className={`center_desc_wrap md:center_desc_nobg flex flex-col md:flex-row gap-5 justify-center align-middle xl:h-[522px] md:h-[533px] py-4`}
          >
            <div className="flex items-center justify-center md:justify-normal">
              <div className="w-[350px] md:w-[360px] text-white xl:w-[600px] grid gap-y-4 xl:gap-y-8">
                <h6 className="mb-2 text-xl font-bold xl:text-5xl max-md:text-center">
                  VR Simulation Platform
                </h6>
                <p className=" xl:text-lg max-md:text-center">
                  Prepare employees for workplace activities or tasks that are
                  dangerous, time-sensitive, or expensive by having them
                  practice in a safe and controlled simulated environment.
                  Improve workplace safety, accuracy, and readiness to ensure
                  employees are prepared for the real thing by leveraging the
                  benefits of our proprietary and internal VR Simulation
                  Platform. Multiplayer is available so supervisors can observe
                  and correct employee performance in real-time and assign
                  learning modules to overcome specific weaknesses.
                </p>
                <div className="xl:flex xl:space-x-3 max-xl:space-y-2">
                  <div className="flex space-x-1 max-md:justify-center">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#FCDC3E] font-bold max-xl:text-sm">
                        Extremely Realistic
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-1 max-md:justify-center">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#FCDC3E] font-bold max-xl:text-sm">
                        Data-driven Insights
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-1 max-md:justify-center">
                    <img src={check_icon} alt="" className="max-xl:w-[16px]" />
                    <div className="flex items-center ">
                      <span className=" text-[#FCDC3E] font-bold max-xl:text-sm">
                        Immediate ROI
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  {/* <WhiteBtn
                    title="Learn More"
                    onAction={() => {
                      navigate("/AR_VR_Custom_Development");
                    }}
                  /> */}
                  <button
                    className="px-4 py-2 font-bold bg-white rounded-lg max-md:w-full text-my-gray xl:text-base"
                    onClick={() => {
                      setShowCase(true);
                    }}
                  >
                    Case Study
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <VideoBox
                className="w-[350px] md:w-[370px] xl:w-[734px] min-h-[250px]"
                url="https://youtu.be/k_gxBAxf3ag"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col justify-center py-5 md:fit_wrap xl:py-8 max-md:border max-md:border-y max-md:border-[#2E90F2]">
        <div>
          <h6 className="mb-2 font-bold text-center xl:text-4xl text-xl max-md:text-[#2E90F2]">
            Personalized AR/VR Development
          </h6>
          <div className="flex justify-center">
            <p className="w-[350px] md:w-[612px] xl:w-[966px] text-center xl:text-lg max-md:font-light">
              Clients rely on us to bring their unique workforce training
              programs to life by incorporating key engagement strategies, like
              experiential learning, gamification and immersive storytelling.
            </p>
          </div>
        </div>

        <Scrollbar
          contentCls="flex md:overflow-x-auto md:space-x-8 md:w-screen xl:w-[85vw] mt-4 mx-auto md:px-4 pb-6 scrollbar-none max-md:flex-col max-md:space-y-5"
          scrollbarCls="scrollbar_box"
        >
          <FitItem
            icon={product_img}
            title="Product Visualization"
            description="A forward-thinking Defense manufacturer requested rapid prototyping from ARSOME Technology to develop several mixed-reality experiences to enable employees to visualize the parts and pieces of a final product."
            url="https://youtu.be/88s5QgZknjg"
          />
          <FitItem
            icon={maintence_trouble}
            title="Maintenance & Troubleshooting"
            description="The DoD chose ARSOME Technology to develop AR maintenance and part identification training so personnel can learn different components and pieces of heavy equipment, improving their readiness for part replacement in the field."
            url=""
          />
          <FitItem
            icon={driving_training}
            title="Driver Training"
            description="ARSOME Technology developed a highly advanced VR driver training program with immersive simulations for companies to assess driver risk, like abiding by speed limits or unloading goods in a busy city intersection with a colleague."
            url="https://youtu.be/79F_ihsNwOE"
          />
          <FitItem
            icon={damage_img}
            title="Damage & Claims Analysis"
            description="A leading insurance company hired ARSOME Technology to create an AR app to help claim adjusters analyze property damage by simulating car accidents, kitchen fires, and basement mold; making training more engaging and impactful."
            url=""
          />
          <FitItem
            icon={simulate_img}
            title="Machine Training"
            description="A workforce development organization tapped ARSOME Technology to develop VR training programs to educate and inspire new employees with different tools and manufacturing processes for the aerospace industry. "
            url="https://youtu.be/pTjP0tEGrvk"
          />
        </Scrollbar>
        {/* <div
          className="scrollbar_box"
          ref={scrollWrapRef}
          onClick={handleWrapClick}
        >
          <div
            className="track-and-thumb"
            ref={scrollTrackRef}
            onClick={handleTrackClick}
            style={{
              width: `${thumbSize}px`,
            }}
            aria-controls="custom-scrollbars-content"
          ></div>
        </div> */}

        {/* <h6 className="mt-4 text-base font-bold leading-normal text-center xl:text-4xl max-md:text-[#2E90F2]">
          Learn more about our expertise to ensure <br />
          we’re a good fit for your project.
        </h6>
        <div className="flex justify-center mt-8 max-md:hidden">
          <WhiteBtn
            title="Are We A Fit?"
            onAction={() => {
              navigate("/Are_We_A_Fit");
            }}
          />
        </div> */}
      </section>
      {/* <section className="flex flex-col justify-center py-5 fit_wrap xl:py-8">
        <div className="flex max-md:flex-col md:flex-row xl:flex-col md:justify-center">
          <div className="max-md:w-[344px] max-md:mx-auto xl:mx-auto max-md:mb-4 md:flex">
            <div className="items-center mx-auto md:flex">
              <img
                src={csr_poster}
                alt=""
                className="max-md:w-[139px] xl:w-[300px] md:w-[139px] max-md:mx-auto"
              />
            </div>
            <div className="md:w-[600px] xl:w-[890px] md:mx-3 xl:mx-3 max-md:mx-auto xl:grid md:flex pt-4">
              <div className="py-4">
                <h6 className="mb-3 text-xl font-bold text-center xl:text-4xl">
                  STEM Career Exploration & CRS Ecosystem
                </h6>
                <div className="flex justify-center">
                  <p className="w-[350px] md:w-[410px] xl:w-[890px] xl:text-center md:text-start xl:text-lg max-md:text-center">
                    To ensure young people meet your workforce challenges of
                    tomorrow, they need exceptional career readiness
                    opportunities today. Introducing our{' '}
                    <b>STEM Corporate Social Responsibility (CRS)</b> ecosystem
                    of programs to present and inspire youth with your brand,
                    careers, and industry expertise to succeed. Purchase a
                    turn-key program or customize one reflecting your brand and
                    corporate outreach strategy.
                  </p>
                </div>
              </div>
              <div className="flex items-center xl:justify-center">
                <WhiteBtn
                  title="Learn More"
                  onAction={() => {
                    navigate('/CSR');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="py-5 fit_wrap xl:py-8">
        <h6 className="mb-2 font-bold text-center xl:text-4xl">
          Trusted and Renowned
        </h6>
        <div className="flex justify-center">
          <p className="w-[344px] md:w-[730px] xl:w-[1034px] text-center text-xs md:text-sm xl:text-base">
            We work with clients from project inception to project deployment by
            executing a 4-phase project development process -- using Project
            Management Institute standards. Each engagement is unique and
            personalized based on the project requirements and complexity.
          </p>
        </div>
        <div className="flex justify-center">
          <div className="flex mt-3 bg-white w-max xl:py-8 xl:px-8 xl:rounded-2xl xl:space-x-6 max-md:space-x-3 max-md:rounded-md md:p-3 md:rounded-xl md:space-x-4 max-md:p-2">
            <img src={wall_street_icon} alt="" className="max-md:w-[40px] md:w-[90px]" />
            <img
              src={connecticut_children}
              alt=""
              className="max-md:w-[32px] md:w-[70px]"
            />
            <img src={harvard_icon} alt="" className="max-md:w-[70px] md:w-[140px]" />
            <img
              src={defense_department_icon}
              alt=""
              className="max-md:w-[60px] md:w-[100px]"
            />
            <img src={mit_media} alt="" className="max-md:w-[32px] md:w-[70px]" />
            <img src={phizer_icon} alt="" className="max-md:w-[32px] md:w-[70px]" />
          </div>
        </div>
      </section> */}
      {/* <section className="flex justify-center py-5 fit_wrap xl:py-8 ">
        <div className="xl:w-full 2xl:w-[1400px]">
          <div className="flex justify-center mb-3 gap-x-3 xl:gap-x-6">
            <div className="flex items-center">
              <h6 className="hidden text-lg font-bold md:block xl:text-2xl">
                AR/VR Education and Insight
              </h6>
            </div>
            <WhiteBtn
              title="VR/AR"
              disabled={eduType !== 'VR/AR'}
              onAction={(val) => setEduType(val)}
            />
            <WhiteBtn
              title="Education"
              disabled={eduType !== 'Education'}
              onAction={(val) => setEduType(val)}
            />
            <WhiteBtn
              title="Technology"
              disabled={eduType !== 'Technology'}
              onAction={(val) => setEduType(val)}
            />
          </div>
          <div className="flex flex-col flex-shrink-0 md:flex-row md:rounded-lg md:bg-white md:p-4 md:gap-3 xl:gap-4">
            <div className="p-5 md:p-0 md:w-[328px] 2xl:w-[400px]">
              <div className="flex justify-center">
                <img
                  src={firstBlog.src}
                  alt=""
                  className="rounded-md w-[350px] md:w-[270px] xl:w-[350px]"
                />
              </div>
              <div className="flex gap-3 mt-2 md:text-txt_blue">
                <h6 className="font-bold ">News</h6>
                <span>|</span>
                <span>Posted on </span>
                <h6 className="font-bold">{firstBlog.vdate}</h6>
              </div>
              <h6 className="mt-2 font-bold md:text-txt_blue xl:text-xl">
                {firstBlog.title}
              </h6>
              <p className="md:text-my-gray md:text-sm xl:text-base">
                {firstBlog.description}
              </p>
              <NavLink to={firstBlog.url ?? ''} target="_blank">
                <h6 className="mt-2 font-bold underline md:text-txt_blue md:text-sm xl:text-base">
                  Read More
                </h6>
              </NavLink>
            </div>
            <div
              className={`overflow-x-auto md:overflow-auto w-screen md:w-auto border-l-2 border-b_blue/40 md:ps-4 xl:pt-4 scrollbar-thumb-gray-100 scrollbar-thin scrollbar-track-my-blue`}
            >
              <div
                className={`flex ms-4 md:ms-0 md:grid md:grid-cols-1 xl:grid-cols-2 xl:gap-x-5 md:gap-y-8`}
              >
                {blogList.map((item, index) => (
                  <NewItem
                    key={index}
                    url={item.url}
                    image={item.src}
                    postDate={item.vdate}
                    title={item.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Modal
        show={showCase}
        onClose={() => setShowCase(false)}
        dismissible
        theme={{
          content: {
            base: 'flex h-full w-full p-4 flex items-center md:h-auto',
            inner:
              'rounded-lg bg-white shadow dark:bg-gray-700 max-md:w-full md:w-[90vw]',
          },
        }}
      >
        <Modal.Header className="pb-0 border-none"></Modal.Header>
        <Modal.Body className="space-y-4">
          {/* <div>
            <img src={org_log} alt="" className="w-[260px] mx-auto" />
          </div> */}
          <div className="space-y-5">
            <h6 className="text-[#2E90F2] font-semibold">
              Let's get you the right case study. Tell us a little about your
              training needs.
            </h6>
            <div>
              <div className="relative xl:h-[200px] md:h-[220px] max-md:h-[250px]">
                <Textarea
                  className={`block absolute rounded-lg border-[#2C8AE8] border xl:h-[200px] md:h-[220px] max-md:h-[250px] z-10 resize-none ${
                    nonEmpty ? 'bg-white' : 'bg-transparent'
                  }`}
                  {...register('content', { required: true })}
                  rows={7}
                />
                {!nonEmpty && (
                  <div className="text-[#92A8A4] space-y-2 text-sm absolute px-3 py-2 rounded-lg border-[#2C8AE8] border xl:h-[200px] md:h-[220px] max-md:h-[250px]">
                    <ul className="space-y-3 list-disc list-outside ps-3">
                      <li>
                        Do employees need to learn how to operate heavy
                        equipment for improved readiness?
                      </li>
                      <li>
                        Do you need to simulate workforce challenges,
                        situations, and hazards for better workforce safety?
                      </li>
                      <li>
                        Do employees need to learn the best practices for
                        maintaining or replacing malfunctioning parts or pieces?
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <TextInput
              type="text"
              placeholder="Enter your email address"
              {...register('email', { required: true })}
            />
            <div>
              <button
                className="w-full py-2 font-bold text-white rounded-md tracker_select_bar"
                disabled={!isValid}
                onClick={() => sendInquiry()}
              >
                {/* <Spinner size="sm" className="me-2" /> */}
                {isSending && <Spinner size="sm" className="me-2" />}
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <InquiryResultModal
        show={showMessage}
        success={isSuccess}
        closeAction={() => setShowMessage(false)}
      />
    </div>
  );
};
